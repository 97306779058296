import * as React from "react";
import Container from "../../components/container";
import Layout from "../../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  // description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  introSection,
} from "../subpage.module.scss";

const SlaPage = () => {
  return (
    <Layout pageTitle="SLA" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
        <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>SLA 7x24 Support</h1>
                <p className="text-medium">
                Für Kunden mit dem Anspruch an eine rund um die Uhr verfügbare i-engine. Voraussetzung nur zusammen mit einem SLA 5x9 Silver, Gold oder Patinum
                </p>

                <h3 className={marginTopSubstitute}>Ihr Nutzen</h3>
               <ul>
                  <li>Sie erreichen uns Tag und Nacht, am Wochenende und an Feiertagen</li>
                  <li>Wir stellen den Betrieb Ihrer i-engine rund um die Uhr sicher</li>
                  <li>Dedizierte Hotline ausserhalb der Büroarbeitszeiten</li>
                  <li>Transparentes bedarfsgerechtes Abrechnungsmodell</li>
              </ul>

              <h3 className={marginTopSubstitute}>Unser Angebot</h3>
              <ul>
                <li>Erweiterung der Support-Leistungen auf 7x24</li>
                <li>the i-engineers organisiert einen Pikettdienst</li>
                <li>Erreichbar über eine spezielle Telefonnummer</li>
                <li>Es steht immer ein erfahrener deutsch sprechender Mitarbeiter bereit</li>
                <li>Während einer Woche hat immer der gleiche Mitarbeiter Pikettdienst</li>
                <li>Die Reaktionszeit entspricht in der Regel dem Standard aus den W&S-Verträgen (SIK, SWICO)</li>
                <li>Voraussetzung: <span className="italic-blue"> mindestens "SLA 5x9, Silver Level"</span></li>
              </ul>
              <p>*Je Kalenderjahr, kündbar 3 Mt im Voraus.</p>
                
              </div>
            </div>

            
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default SlaPage;
